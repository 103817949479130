import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cavitation-risk-popup',
  templateUrl: './cavitation-risk-popup.component.html',
  styleUrls: ['./cavitation-risk-popup.component.scss'],
})
export class CavitationRiskPopupComponent implements OnInit {
  @Input() notes: [] = [];
  @Input() warnings: [] = [];
  @Input() popupType: 'success' | 'warning' | 'error' = 'success';
  @Output() onClose = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {
  }

  closePopup() {
    this.onClose.emit();
  }
}
