import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import 'hammerjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideMenuComponent } from './common/side-menu/side-menu.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SubmainCalculatorPageComponent } from './pages/submain-calculator-page/submain-calculator-page.component';
import { BlockCharacteristicsComponent } from './components/calculator-components/block-characteristics/block-characteristics.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ToggleButtonComponent } from './common/toggle-button/toggle-button.component';
import { BlockQuestionComponent } from './components/calculator-components/block-question-lateral/block-question.component';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MulitpleToggleButtonComponent } from './common/mulitple-toggle-button/mulitple-toggle-button.component';
import { HttpClientModule } from '@angular/common/http';
import { SubmainCharacteristicsComponent } from './components/calculator-components/submain-characteristics/submain-characteristics.component';
import { MatTableModule } from '@angular/material/table';
import { GeneralPopupComponent } from './common/general-popup/general-popup.component';
import { WarningPopupComponent } from './components/popups/warning-popup/warning-popup.component';
import { ErrorPopupComponent } from './components/popups/error-popup/error-popup.component';
import { ClearPopupComponent } from './components/popups/clear-popup/clear-popup.component';
import { SnackBarComponent } from './common/snack-bar-component/snack-bar-component.component';
import { GraphComponent } from './common/graph/graph.component';
import { IlustrationSlopeComponent } from './components/calculator-components/ilustration-slope/ilustration-slope.component';
import { IlustrationSectionsComponent } from './components/calculator-components/ilustration-sections/ilustration-sections.component';
import { IlustrationResultComponent } from './components/calculator-components/ilustration-result/ilustration-result.component';
import { LoaderComponent } from './common/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableFilterModule } from 'mat-table-filter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ImgTooltipComponent } from './common/img-tooltip/img-tooltip.component';
import { EditComponent } from './components/buttons/edit/edit.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { MessagePopupComponent } from './components/popups/message-popup/message-popup.component';
import { ForgotPasswordPopupComponent } from './components/popups/forgot-password-popup/forgot-password-popup.component';
import { ResetPasswordPopupComponent } from './components/popups/reset-password-popup/reset-password-popup.component';
import { CalcNavbarComponent } from './components/calculator-components/calc-navbar/calc-navbar.component';
import { CalcHeaderComponent } from './components/calculator-components/header/header.component';
import { CalculatorMainComponent } from './pages/calculator-main/calculator-main.component';
import { CalcHomeComponent } from './pages/calculator-main/calc-home/calc-home.component';
import { SnackbarComponent } from './common/snackbar/snackbar.component';
import { SectionCardComponent } from './components/section-card/section-card.component';
import { CalcHelpComponent } from './pages/calculator-main/calc-help/calc-help.component';
import { FaqComponent } from './components/faq/faq.component';
import { VideoTutorialsComponent } from './components/video-tutorials/video-tutorials.component';
import { VideoTutorialCardComponent } from './components/video-tutorial-card/video-tutorial-card.component';
import { HelpContactComponent } from './components/help-contact/help-contact.component';
import { SettingsPopupComponent } from './components/popups/settings-popup/settings-popup.component';
import { LateralCalculatorPageComponent } from './pages/lateral-calculator-page/lateral-calculator-page.component';
import { AdvancedSettingComponent } from './components/calculator-components/advanced-setting/advanced-setting.component';
import { BlockEmitterComponent } from './components/calculator-components/block-emitter/block-emitter.component';
import { CanActivateLoggedUser } from './shared/CanActivateLoggedUser';
import { CalcHeaderMobileComponent } from './components/calculator-components/calc-header-mobile/calc-header-mobile.component';
import { FooterMobileComponent } from './common/footer-mobile/footer-mobile.component';
import { CalcNavbarMobileComponent } from './components/calculator-components/calc-navbar-mobile/calc-navbar-mobile.component';
import { MatListModule } from '@angular/material/list';
//cognito configuration
import Amplify from 'aws-amplify';
import { LateralCharacteristicsComponent } from './components/calculator-components/lateral-characteristics/lateral-characteristics.component';
import { CalcSettingsComponent } from './pages/calculator-main/calc-mobile/calc-settings/calc-settings.component';
import { HelpContactUsComponent } from './pages/calculator-main/calc-mobile/help-contact-us/help-contact-us.component';
import { HelpCommonQuestionComponent } from './pages/calculator-main/calc-mobile/help-common-question/help-common-question.component';
import { FooterDesktopComponent } from './common/footer-desktop/footer-desktop.component';
import { IlustrationPopupComponent } from './components/popups/ilustration-popup/ilustration-popup.component';
import { BlockResultMobileComponent } from './components/calculator-components/block-result-mobile/block-result-mobile.component';
import { ChartExampleComponent } from './common/chart-example/chart-example.component';
import { SiteUnderMaintenancePopupComponent } from './components/popups/site-under-maintenance-popup/site-under-maintenance-popup.component';
import { MainlineCalculatorPageComponent } from './pages/mainline-calculator-page/mainline-calculator-page.component';
import { MainlineCharacteristicsComponent } from './components/calculator-components/mainline-characteristics/mainline-characteristics.component';
import { BlockQuestionComponentNew } from './components/calculator-components/block-question/block-question.component';
import { PipeSectionComponent } from './components/calculator-components/pipe-section/pipe-section.component';
import { FieldComponentComponent } from './common/field-component/field-component.component';
import { ElevationSectionComponent } from './components/calculator-components/elevation-section/elevation-section.component';
import { ResultSectionComponent } from './components/calculator-components/result-section/result-section.component';
import { GraphPopupComponent } from './components/popups/graph-popup/graph-popup.component';
import { PdfComponent } from './common/pdf/pdf.component';
import { SlopeComponent } from './components/calculator-components/slope/slope.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OfflineToggleComponent } from './components/offline-toggle/offline-toggle.component';
import { ConfirmationPopupComponent } from './components/popups/confirmation-popup/confirmation-popup.component';
import { InputComponentComponent } from './common/input-component/input-component.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ValveCalculatorPageComponent } from './pages/valve-calculator-page/valve-calculator-page.component';
import { ValveCharacteristicsComponent } from './components/calculator-components/valve-characteristics/valve-characteristics.component';
import { ValveResultsComponent } from './components/valve-results/valve-results.component';
import { EnergyCalculatorPageComponent } from './pages/energy-calculator-page/energy-calculator-page.component';
import { EnergyCharacteristicsComponent } from './components/calculator-components/energy-characteristics/energy-characteristics.component';
import { DeviceLessCalcResultsComponent } from './components/calculator-components/device-less-calc-results/device-less-calc-results.component';
import { IrrigationCalculatorPageComponent } from './pages/irrigation-calculator-page/irrigation-calculator-page.component';
import { IrrigationCharacteristicsComponent } from './components/calculator-components/irrigation-characteristics/irrigation-characteristics.component';
import { ShiftCalculatorPageComponent } from './pages/shift-calculator-page/shift-calculator-page.component';
import { ShiftCharacteristicsComponent } from './components/calculator-components/shift-characteristics/shift-characteristics.component';
import { MatrixResultsComponent } from './components/matrix-results/matrix-results.component';
import { MatrixTableComponent } from './components/matrix-table/matrix-table.component';
import { ValvesMainPageComponent } from './pages/valves-main-page/valves-main-page.component';
import { ValvesCardComponent } from './pages/valves-main-page/valves-card/valves-card.component';
import { ProductFinderComponent } from './pages/product-finder/product-finder.component';
import { OverflowTooltipDirective } from './directives/overflow-tooltip/overflow-tooltip.directive';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component'
import { CavitationRiskPopupComponent } from './components/popups/cavitation-risk-popup/cavitation-risk-popup.component';



// import awsconfig from '../aws-exports';
Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-1_rHoeRhAU1',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '1jiaddc09f9tvf68cj7tdu0b6c',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false
  }
});

@NgModule({
  declarations: [
    EditComponent,
    AppComponent,
    SideMenuComponent,
    SubmainCalculatorPageComponent,
    BlockCharacteristicsComponent,
    ToggleButtonComponent,
    BlockQuestionComponent,
    MulitpleToggleButtonComponent,
    SubmainCharacteristicsComponent,
    GeneralPopupComponent,
    WarningPopupComponent,
    ErrorPopupComponent,
    ClearPopupComponent,
    SnackBarComponent,
    GraphComponent,
    IlustrationSlopeComponent,
    IlustrationSectionsComponent,
    IlustrationResultComponent,
    LoaderComponent,
    ImgTooltipComponent,
    EditComponent,
    SignUpComponent,
    SignInComponent,
    MessagePopupComponent,
    ForgotPasswordPopupComponent,
    ResetPasswordPopupComponent,
    CalcNavbarComponent,
    CalculatorMainComponent,
    CalcHomeComponent,
    SnackbarComponent,
    SectionCardComponent,
    CalcHelpComponent,
    FaqComponent,
    VideoTutorialsComponent,
    VideoTutorialCardComponent,
    HelpContactComponent,
    SettingsPopupComponent,
    CalcHeaderComponent,
    LateralCalculatorPageComponent,
    AdvancedSettingComponent,
    BlockEmitterComponent,
    LateralCharacteristicsComponent,
    CalcHeaderMobileComponent,
    FooterMobileComponent,
    CalcNavbarMobileComponent,
    FooterDesktopComponent,
    CalcNavbarMobileComponent,
    CalcSettingsComponent,
    HelpContactUsComponent,
    HelpCommonQuestionComponent,
    IlustrationPopupComponent,
    BlockResultMobileComponent,
    ChartExampleComponent,
    SiteUnderMaintenancePopupComponent,
    MainlineCalculatorPageComponent,
    MainlineCharacteristicsComponent,
    BlockQuestionComponentNew,
    PipeSectionComponent,
    FieldComponentComponent,
    ElevationSectionComponent,
    SlopeComponent,
    ResultSectionComponent,
    GraphPopupComponent,
    PdfComponent,
    OfflineToggleComponent,
    ConfirmationPopupComponent,
    InputComponentComponent,
    ValveCalculatorPageComponent,
    ValveCharacteristicsComponent,
    ValveResultsComponent,
    EnergyCalculatorPageComponent,
    EnergyCharacteristicsComponent,
    DeviceLessCalcResultsComponent,
    IrrigationCalculatorPageComponent,
    IrrigationCharacteristicsComponent,
    ShiftCalculatorPageComponent,
    ShiftCharacteristicsComponent,
    SnackBarComponent,
    MatrixResultsComponent,
    MatrixTableComponent,
    ValvesMainPageComponent,
    ValvesCardComponent,
    ProductFinderComponent,
    OverflowTooltipDirective,
    BreadcrumbsComponent,
    CavitationRiskPopupComponent,
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSelectModule,
    FormsModule,
    MatSidenavModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSnackBarModule,
    MatRadioModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    HttpClientModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTableFilterModule,
    MatTabsModule,
    MatButtonModule,
    MatDialogModule,
    HammerModule,
    MatExpansionModule,
    MatListModule,
    MatSlideToggleModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    MatAutocompleteModule
  ],
  providers: [CanActivateLoggedUser],
  bootstrap: [AppComponent]
})
export class AppModule { }
