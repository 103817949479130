import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { PdfService } from "src/app/services/pdf.service";
import { TextService } from "src/app/services/text.service";
import { EtransaltionGroups, EvalveQueryTypes } from "src/app/shared/enum";

@Component({
  selector: "app-valve-results",
  templateUrl: "./valve-results.component.html",
  styleUrls: ["./valve-results.component.scss"],
})
export class ValveResultsComponent implements OnInit {
  @ViewChild("pdf_to_export") pdfView: ElementRef;
  @Input() queryIndex: number;
  @Input() calculationResults: any;
  @Input() tableColumns: any;

  //mobile results
  mobileResultsTitles: any[] = [];

  //pdf results
  pdfUserInputsColumns: any[] = [];
  pdfResultsColumns: any[] = [];
  inputTableValues: any[];
  dateDisplay: string = " ";

  EqueryTypes = EvalveQueryTypes;
  EtransaltionGroups = EtransaltionGroups;
  quickReliefResultsText: string = '';
  valvesResults: any = [];

  showCavitationPopup: boolean = false;
  public popupType: 'success' | 'warning' | 'error' = 'success';

  constructor(
    public textService: TextService,
    public dataService: DataService,
    public pdfService: PdfService
  ) { }

  ngOnInit(): void {
    if (this.queryIndex === this.EqueryTypes.cavitation_risk) {
      const hasWarnings = this.calculationResults.warnings?.length > 0;
      const hasOkNote = this.calculationResults.notes?.some(
        (note) => note.note.includes('OK')
      );
  
      if (hasWarnings) {
        this.popupType = 'error';
      } else if (hasOkNote) {
        this.popupType = 'success';
      } else {
        this.popupType = 'warning';
      }
  
      this.showCavitationPopup = true;
    }
    
    this.builtQuickReliefResultsText();
    this.buildPdfColumns();
    this.setValuesForUserInputsTable();
    this.buildDateStr();
    this.buildMobileColumns();
    this.setValvesResults();
  }

  setValvesResults() {
    const { valves } = this.calculationResults;
    if (this.queryIndex !== this.EqueryTypes.valve_diameter) {
      this.valvesResults = valves;
      return;
    };
    const convertToStrings = (obj: any) => {
      const stringifiedObj: { [key: string]: string } = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          stringifiedObj[key] = obj[key].toString();
        }
      }
      return stringifiedObj;
    };
    const uniqueSet = new Set();
    const uniqueArray = valves.filter((item) => {
      const stringifiedObj = convertToStrings(item);
      const uniqueKey = JSON.stringify(stringifiedObj); // Use JSON.stringify here for uniqueness check
      if (!uniqueSet.has(uniqueKey)) {
        uniqueSet.add(uniqueKey);
        return true;
      }
      return false;
    });
    this.valvesResults = uniqueArray;
  };

  builtQuickReliefResultsText() {
    if (this.queryIndex !== this.EqueryTypes.quick_relief_valve) return;
    const inch = this.calculationResults.diameter_inch + '"';
    const mm = this.calculationResults.diameter_mm + 'mm';
    this.quickReliefResultsText = inch.padEnd(12, ' ') + mm;
  }

  resultsTitle() {
    switch (this.queryIndex) {
      case this.EqueryTypes.valve_diameter:
        return this.textService.getText(
          EtransaltionGroups.VALVE,
          "valve_diameter_results_title"
        );
      case this.EqueryTypes.pressure_loss:
        return this.textService.getText(
          EtransaltionGroups.VALVE,
          "valve_pressure_loss_results_title"
        );
      default:
        return this.textService.getText(
          EtransaltionGroups.VALVE,
          "valve_molbile_table_title"
        );
    }
  }

  buildPdfColumns() {
    if (this.queryIndex === this.EqueryTypes.quick_relief_valve) {
      const [reliefFlow, setPressure, diameters] = this.tableColumns;
      this.pdfUserInputsColumns = [reliefFlow, setPressure, diameters];
    }
    else {
      const [
        family,
        model,
        diameter_mm,
        diameter_inch,
        material,
        kv_cv,
        flow_rate,
        head_loss,
      ] = this.tableColumns;

      this.pdfResultsColumns = [
        model,
        //need to also think of inch just for the units display
        diameter_mm,
        material,
        kv_cv,
        head_loss,
      ];

      const maximum_allowed_pressure_text = {
        display: this.textService.getText(
          EtransaltionGroups.VALVE,
          "valve_results_maximum_allowed_pressure_loss_label"
        ),
        units: this.textService.getUnitsAsText("m_pressure"),
      };

      const limited_pressure_loss_text = {
        display: this.textService.getText(
          EtransaltionGroups.VALVE,
          "valve_results_limited_pressure_loss_label"
        ),
        units: this.textService.getUnitsAsText("m_pressure"),
      };

      if (this.queryIndex === this.EqueryTypes.valve_diameter) {
        this.pdfUserInputsColumns = [
          family,
          flow_rate,
          maximum_allowed_pressure_text,
          limited_pressure_loss_text,
        ];
      }

      if (this.queryIndex === this.EqueryTypes.pressure_loss) {
        this.pdfUserInputsColumns = [
          family,
          model,
          diameter_mm,
          diameter_inch,
          flow_rate,
        ];
      }
    }
  }

  setValuesForUserInputsTable() {
    let inputTableValues = [];
    const { requestParameters: tableValues } = this.calculationResults;

    if (this.queryIndex === this.EqueryTypes.pressure_loss) {
      inputTableValues = [
        tableValues.valve_family,
        tableValues.valve_model,
        tableValues.diameter_mm,
        tableValues.diameter_inch,
        tableValues.flowRate,
      ];
    }
    if (this.queryIndex === this.EqueryTypes.valve_diameter) {
      inputTableValues = [
        tableValues.valve_family,
        tableValues.flowRate,
        tableValues.maximumAllowedPressureLoss,
        tableValues.limitedPressureLoss,
      ];
    }
    if (this.queryIndex === this.EqueryTypes.quick_relief_valve) {
      inputTableValues = [
        tableValues.reliefFlow,
        tableValues.reliefPressure,
        this.quickReliefResultsText,
      ];
    }
    this.inputTableValues = inputTableValues;
  }

  buildDateStr() {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    this.dateDisplay = `${day}-${months[month]}-${year}`;
  }

  exportToPDF() {
    let content: Element = this.pdfView.nativeElement;
    let title = "results";
    let options = {
      useCORS: true,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        scale: 2,
        width: 595.276,
        backgroundColor: "#f4f7fb",
        scrollX: 0,
        scrollY: 0,
      },
      jsPDF: { orientation: "portrait", unit: "mm", format: "a4" },
    };
    this.pdfService.generatePDF(content, title, options);
  }

  buildMobileColumns() {
    if (this.queryIndex === this.EqueryTypes.quick_relief_valve) {
      const [reliefFlow, setPressure, diameters] = this.tableColumns;
      reliefFlow.key = "relief_flow";
      setPressure.key = "set_pressure";
      diameters.key = "diameters";

      this.mobileResultsTitles = [reliefFlow, setPressure, diameters];
    }
    else {
      const [
        family,
        model,
        diameter_mm,
        diameter_inch,
        material,
        kv_cv,
        flow_rate,
        head_loss,
      ] = this.tableColumns;

      family.key = "valve_family";
      model.key = "valve_model";
      diameter_mm.key = "diameter_mm";
      diameter_inch.key = "diameter_inch";
      material.key = "valve_material";
      kv_cv.key =
        this.dataService.selected_units === this.dataService.units_list[0]
          ? "kv"
          : "cv";
      flow_rate.key = "flow_rate";
      head_loss.key =
        this.queryIndex === this.EqueryTypes.valve_diameter
          ? "pressure_loss"
          : "head_loss";

      if (this.queryIndex === this.EqueryTypes.valve_diameter) {
        this.mobileResultsTitles = [
          diameter_mm,
          diameter_inch,
          family,
          model,
          material,
          kv_cv,
          flow_rate,
          head_loss,
        ];
      } else if (this.queryIndex === this.EqueryTypes.pressure_loss) {
        this.mobileResultsTitles = [
          head_loss,
          family,
          model,
          diameter_mm,
          diameter_inch,
          material,
          kv_cv,
          flow_rate,
        ];
      }
    }
  }
}
