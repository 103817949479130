import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { of } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { Ecalculator } from "src/app/shared/enum";

@Component({
  selector: "input-component",
  templateUrl: "./input-component.component.html",
  styleUrls: ["./input-component.component.scss"],
})
export class InputComponentComponent implements OnInit, AfterViewInit {
  @Input() type;
  @Input() control: FormControl;
  @Input() label;
  @Input() tooltip;
  @Input() isRadio: boolean = false;
  @Input() isItSelect: boolean = false;
  @Input() options: any[];
  @Input() autocompleteOptions;
  @Input() isItDisable: boolean = false;
  @Input() isSelectDisable: boolean = false;
  @Input() suffix: string;
  @Input() option_name;
  @Input() selected_option;
  @Input() isItCurrentInputToFill: boolean = false;
  @Input() isFullWidth: boolean = false;
  @Input() isItAutocomplete: boolean = false;
  @Input() isInputWiderMedium: boolean = false;
  @Input() isSpellcheck: boolean = true;
  @Input() addedClass: string;
  @Input() calculatorIndex: number;
  @Input() changeHandler: any = () => { };
  @Input() showClearButton: boolean = false;

  Ecalculator = Ecalculator;

  autoComplete: MatAutocompleteTrigger;
  constructor(private cdr: ChangeDetectorRef, public dataService: DataService) {
    if (this.selected_option) {
      this.control.setValue(this.selected_option);
    }
  }

  ngOnInit(): void {
    if (this.isItAutocomplete) {
      window.addEventListener("scroll", this.scrollEvent, true);
      this.filterOptions();
    }
  }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  isTextFieldType: boolean = false;

  togglePasswordFieldType() {
    this.isTextFieldType = !this.isTextFieldType;
  }

  clearSelection(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.control.setValue('', { emitEvent: true });
  }

  scrollEvent = (event: any): void => {
    if (this.autoComplete?.panelOpen) this.autoComplete.updatePosition();
  };

  filterOptions() {
    this.control.valueChanges.subscribe((value) => {
      this.autocompleteOptions = of(this._filter(value == null ? "" : value));
    });
  }

  isInputWiderLargeCalcType() {
    const calcTypes = [
      Ecalculator.valve,
      Ecalculator.irrigation,
      Ecalculator.shift,
      Ecalculator.energy,
    ];
    return calcTypes.includes(this.calculatorIndex);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options?.filter((option) =>
      option[this.option_name].toLowerCase().includes(filterValue)
    );
  }
}
