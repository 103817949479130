import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import { ValveService } from "src/app/services/valve.service";
import {
  Ecalculator,
  EtransaltionGroups,
  EvalveQueryTypes,
} from "src/app/shared/enum";
import { ValveDiameters } from "src/app/shared/types";

@Component({
  selector: "app-valve-characteristics",
  templateUrl: "./valve-characteristics.component.html",
  styleUrls: ["./valve-characteristics.component.scss"],
})
export class ValveCharacteristicsComponent implements OnInit {
  @Input() queryIndex: number;
  @Input() valveForm: FormGroup;
  @Input() valveFamilyOptions: any[];
  @Input() valveModelOptions: any[];
  @Input() flowRateValidationMax: any[];
  @Input() cavitationRiskPressureMax: number;
  @Input() cavitationRiskPressureMin: number;
  @Input() pressureLossValidationMax: number;
  @Input() quickReliefValvePressureMin: number;
  @Input() quickReliefValvePressureMax: number;
  @Input() setPressureMin: number;
  @Input() setPressureMax: number;
  @Input() upstreamPressure: any[];
  @Input() downstreamPressure: any[];
  @Input() reliefFlow: any[];
  @Input() setPressure: any[]
  @Input() user_id: string;
  @Input() inputChangeHandler: any;

  diameterOptions: ValveDiameters[];
  diameterMmOptions: string[];
  diameterInchOptions: string[];

  EqueryTypes = EvalveQueryTypes;
  Ecalculator = Ecalculator;
  EtransaltionGroups = EtransaltionGroups;

  selected_units = this.dataService.selected_units;
  units_list = this.dataService.units_list;

  constructor(
    public dataService: DataService,
    public textService: TextService,
    public valveService: ValveService
  ) { }

  ngOnInit(): void {
    this.dataService.changeValveQuestionForm.subscribe((res) => {
      setTimeout(() => {
        this.addOnChangeListeners();
      });
    });
    this.addOnChangeListeners();
    if (
      this.queryIndex === EvalveQueryTypes.pressure_loss ||
      this.queryIndex === EvalveQueryTypes.valve_diameter
    ) {
      this.setDefaultFamily();
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['queryIndex']) &&
      (
        this.queryIndex === EvalveQueryTypes.pressure_loss ||
        this.queryIndex === EvalveQueryTypes.valve_diameter
      )) {
      this.setDefaultFamily();
    }
  }

  addOnChangeListeners() {
    if (this.queryIndex !== EvalveQueryTypes.cavitation_risk && this.queryIndex !== EvalveQueryTypes.quick_relief_valve) {
      this.onChangeFamily();
      this.onChangeFlowRate();
      if (this.queryIndex === EvalveQueryTypes.pressure_loss) {
        this.onChangeModel();
        this.onChangeDiameters();
      }
      if (this.queryIndex === EvalveQueryTypes.valve_diameter) {
        this.onChangeMaxAllowedPressure();
      }
    }
  }

  onChangeFamily() {
    if (this.queryIndex === EvalveQueryTypes.pressure_loss) {
      this.valveForm.get("valveFamily").valueChanges.subscribe((value) => {
        this.handleChange(this.handleFamilyChange, true);
      });
    }
  }

  onChangeModel() {
    this.valveForm.get("valveModel").valueChanges.subscribe((value) => {
      this.handleChange(this.handleModelChange);
    });
  }

  onChangeDiameters() {
    this.valveForm
      .get("valveDiameterInch")
      .valueChanges.subscribe((diameterInch) => {
        this.handleChangeDiameterInch(diameterInch);
      });

    this.valveForm
      .get("valveDiameterMm")
      .valueChanges.subscribe((diameterMm) => {
        this.handleChangeDiameterMm(diameterMm);
      });
  }

  onChangeFlowRate() {
    this.valveForm.get("flowRate").valueChanges.subscribe((flowRate) => {
      this.handleChangeFlowRate(flowRate);
    });
  }

  onChangeMaxAllowedPressure() {
    this.valveForm
      .get("maxAllowedPressureLoss")
      .valueChanges.subscribe((pressure) => {
        this.handleChangeMaxAllowedPressureLoss(pressure);
      });
  }

  getControlsValues() {
    const family = this.valveForm.get("valveFamily").value;
    const model = this.valveForm.get("valveModel").value;
    const controlsValues = {
      family: family,
      model: model,
    };
    return controlsValues;
  }

  get controls() {
    return this.valveForm.controls;
  }

  private setDefaultFamily(): void {
    if (!this.valveFamilyOptions?.length) {
      return;
    }

    const controlValvesOption = this.valveFamilyOptions.find(
      (option) => option === 'Control valves'
    );

    if (controlValvesOption) {
      this.valveForm.get('valveFamily').setValue(controlValvesOption, {
        emitEvent: false,
      });
    } else {
      this.valveForm.get('valveFamily').setValue(
        this.valveFamilyOptions[0],
        { emitEvent: false }
      );
    }

    if (this.queryIndex === EvalveQueryTypes.pressure_loss) {
      this.handleChange(this.handleFamilyChange, true);
    }
  }
  
  // ----------------------------------------------------- Change Handlers -----------------------------------------------------

  // handle change when need to request the server
  handleChange(handler, isHandleFamily = false) {
    if (isHandleFamily) {
      this.valveForm.get("valveModel").setValue("", { emitEvent: false });
    }
    this.dataService.showLoader = true;
    const body = this.getControlsValues();
    this.valveService.getValvesData(body).subscribe(handler);
  }

  handleFamilyChange = (res) => {
    this.valveForm.get("valveDiameterMm").setValue("", { emitEvent: false });
    this.valveForm.get("valveDiameterInch").setValue("", { emitEvent: false });
    this.valveForm.get("flowRate").setValue("", { emitEvent: false });

    this.valveModelOptions = res.result.valve_characteristics.models;
    // so this can be used when go back on mobile
    this.dataService.valveModelOptions = this.valveModelOptions;
    this.dataService.showLoader = false;
  };

  handleModelChange = (res) => {
    //reset values for diameters and flowrate controls
    this.valveForm.get("valveDiameterMm").setValue("", { emitEvent: false });
    this.valveForm.get("valveDiameterInch").setValue("", { emitEvent: false });
    this.valveForm.get("flowRate").setValue("", { emitEvent: false });
    this.diameterOptions = this.processDiameters(res.result.valve_characteristics.diameters);

    this.diameterMmOptions = this.diameterOptions
      .map(({ diameter_mm }) => diameter_mm)
      .sort(this.sortDiameters);
    this.diameterInchOptions = this.diameterOptions
      .map(({ diameter_inch }) => diameter_inch)
      .sort(this.sortDiameters);

    this.dataService.valveDiameterOptions = this.diameterOptions;
    this.dataService.valveDiameterMmOptions = this.diameterMmOptions;
    this.dataService.valveDiameterInchOptions = this.diameterInchOptions;
    this.dataService.showLoader = false;
  };

  processDiameters = (diameters) => {
    const uniqueSet = new Set<string>();
    const uniqueData = []

    diameters.forEach((item) => {
      // Convert both fields to strings
      const diameter_inch = String(item.diameter_inch);
      const diameter_mm = String(item.diameter_mm);

      // Create a unique key for each combination
      const key = `${diameter_inch}-${diameter_mm}`;

      if (!uniqueSet.has(key)) {
        uniqueSet.add(key);
        uniqueData.push({ diameter_inch, diameter_mm });
      }
    });

    return uniqueData;


  };

  handleChangeDiameterInch = (diameterInch) => {
    if (!this.diameterOptions) {
      this.diameterOptions = this.dataService.valveDiameterOptions;
    }
    const diameters = this.diameterOptions.find(
      ({ diameter_inch }) => diameter_inch === diameterInch
    );
    const { diameter_mm } = diameters;
    const diameterMmControl = this.valveForm.get("valveDiameterMm");
    diameterMmControl.setValue(diameter_mm, { emitEvent: false });
  };

  handleChangeDiameterMm = (diameterMm) => {
    if (!this.diameterOptions) {
      this.diameterOptions = this.dataService.valveDiameterOptions;
    }
    const diameters = this.diameterOptions.find(
      ({ diameter_mm }) => diameter_mm === diameterMm
    );
    const { diameter_inch } = diameters;
    const diameterInchControl = this.valveForm.get("valveDiameterInch");
    diameterInchControl.setValue(diameter_inch, { emitEvent: false });
  };

  handleChangeFlowRate = (flowRate) => {
    const flowRateControl = this.valveForm.get("flowRate");
    flowRateControl.setValue(flowRate, { emitEvent: false });
  };

  handleChangeMaxAllowedPressureLoss = (pressure) => {
    const maxAllowedPressureLossControl = this.valveForm.get(
      "maxAllowedPressureLoss"
    );
    maxAllowedPressureLossControl.setValue(pressure, { emitEvent: false });
    if (
      !(
        maxAllowedPressureLossControl.hasError("min") ||
        maxAllowedPressureLossControl.hasError("max")
      )
    ) {
      this.dataService.showLoader = true;
      const body = { maxAllowedPressureLoss: pressure };
      this.valveService.getLimitedPressureLoss(body).subscribe((res) => {
        if (res.success) {
          const limitedPressureLoss = res.result.limitedPressureLoss;
          const limitedPressureLossControl = this.valveForm.get(
            "limitedPressureLoss"
          );
          limitedPressureLossControl.setValue(limitedPressureLoss, {
            emitEvent: false,
          });
        }
        this.dataService.showLoader = false;
      });
    }
  };

  getQuestionText() {
    switch (this.queryIndex) {
      case EvalveQueryTypes.pressure_loss:
        return this.textService.getText(
          this.EtransaltionGroups.VALVE,
          "valve_calculation_Q_flow_pressure_loss"
        )
      case EvalveQueryTypes.valve_diameter:
        return this.textService.getText(
          this.EtransaltionGroups.VALVE,
          "valve_calculation_Q_flow_diameters"
        )
      case EvalveQueryTypes.cavitation_risk:
        return this.textService.getText(
          this.EtransaltionGroups.VALVE,
          "valve_calculation_Q_cavitation_upstream_and_downstream"
        )
      case EvalveQueryTypes.quick_relief_valve:
        return this.textService.getText(
          this.EtransaltionGroups.VALVE,
          "valve_calculation_Q_quick_relief_flow_rate_and_pressure"
        )
    }
  }

  //sort functions for diameters

  private sortDiameters = (a, b) => {
    // remove " before sort
    let _a = a.toString().replace('"', "");
    let _b = b.toString().replace('"', "");

    if (_a.includes("R") || _b.includes("R")) {
      if (Number(_a.replace("R", "")) === Number(_b.replace("R", ""))) {
        return this.sortForEqualCoefficients(_a, _b);
      } else {
        return this.convertToNumberAndCompare(
          _a.replace("R", ""),
          _b.replace("R", "")
        );
      }
    } else {
      return this.convertToNumberAndCompare(_a, _b);
    }
  };

  sortForEqualCoefficients = (a, b) => {
    if (a.includes("R") && b.includes("R")) {
      return 0;
    } else if (a.includes("R") && !b.includes("R")) {
      return 1;
    } else {
      return -1;
    }
  };

  convertToNumberAndCompare = (a, b) => {
    const returnedValue = Number(a) > Number(b) ? 1 : -1;
    return returnedValue;
  };
}
